/* Section: What */
.icon-services {
  font-size: 120px;
}

.extra-mr {
  margin-right: 15px;
}

.icon-brand {
  font-size: 100px;
}

@media (max-width: 768px) {
  .icon-services {
    font-size: 60px;
  }
  .icon-brand {
    font-size: 60px;
  }
}
